/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import Seo from '@widgets/Seo'
import React from 'react'

export default function TermsAndConditionsPage({ path }) {
  return (
    <Main variant="compact">
      <Seo pageUrl={path} title="Terms and Conditions" />
      <h1>Terms and Conditions</h1>
      <p>
        This is a legal agreement (“Agreement”) between you and Caldera, LLC.
        (“Company,” “Service,” “we,” “us,” or “our”). By using our website,
        located at https://www.guesstherose.com (“Website”), our mobile app,
        and/or other services we provide (collectively, Guess the Rose), or
        otherwise accessing the pages of Guess the Rose, you become a user and
        agree to, and are bound by, the terms and conditions of this Agreement
        for as long as you continue to use the Site or Services. IF YOU DO NOT
        AGREE TO BE BOUND BY THIS AGREEMENT, DO NOT USE THE SITE OR THE
        SERVICES. Your use of, or participation in, certain Services may be
        subject to additional terms, and such terms will be either listed in
        this Agreement or will be presented to you for your acceptance when you
        sign up to use such Services.
      </p>
      <p>
        The Guess the Rose services consist of a service for people to create
        moments together, including interactive content and community services.
        Guess the Rose may offer additional services or revise any of the
        Services, at its discretion, and this Agreement will apply to all
        additional services or revised Services. Guess the Rose also reserves
        the right to cease offering any of the Services.
      </p>
      <p>
        This Agreement is subject to change by Guess the Rose in its sole
        discretion at any time, with or without notice. Your continued use of
        this Site or the Services after the posting of revisions to this
        Agreement will constitute your acceptance of such revisions. Please
        consult the end of this Agreement to determine when the Agreement was
        last revised.
      </p>
      <p>Eligibility</p>
      <ul>
        <ol>
          Minimum Age. If you are under 18 years of age, you may only play Guess
          the Rose with permission of a parent or guardian. We do not knowing
          collection information from persons under 16 years of age.
        </ol>
      </ul>
      <h2>Use of Site and Service</h2>
      <p>
        As a user of the Site (“User”) or a user registered to use any of the
        Services (a “Registered User”), you agree to the following:
      </p>
      <ol>
        <li>
          Creating an Account on Guess the Rose. In order to use Guess the Rose,
          you must create an account using an email, Facebook, Google, or
          Twitter. If you do so via a third party provider, you authorize us to
          access and use certain account information from those serves,
          including but not limited to your public profile and information about
          friends you might share in common with other Guess the Rose users. For
          more information regarding the information we collect from you and how
          we use it, please consult our Privacy Policy.
        </li>
        <li>
          Exclusive Use. Your account is for your personal use only. You may not
          authorize others to use your account, and you may not assign or
          otherwise transfer your account to any other person or entity. You
          acknowledge that Guess the Rose is not responsible for third party
          access to your account that results from theft or misappropriation of
          your login credentials you use to sign up for Guess the Rose. You
          agree to immediately notify Guess the Rose of any disclosure or
          unauthorized use of your login credentials at guesstherosehq@gmail.com
        </li>
        <li>
          Geographic Limitations. Using the Service may be prohibited or
          restricted in certain countries. If you use the Service from outside
          of the United States, you are responsible for complying with the laws
          and regulations of the territory from which you access or use the
          Website or Service. You are not located in, under the control of, or a
          national or resident of any country which the United States has (i)
          embargoed goods, (ii) identified as a “Specially Designated National”,
          or (iii) placed on the Commerce Department’s Table of Deny Orders.
          Registration for, and use of, the Services are void where prohibited.
          To the extent that the Services are not legal in your jurisdiction,
          you may not use the Services. The Services may not be used where
          prohibited by law.
        </li>
        <li>
          Information Submitted. You are solely responsible for, and assume all
          liability regarding, (i) the information and content you contribute to
          the Service; (ii) the information and content you post, transmit,
          publish, or otherwise make available (hereinafter “post”) through the
          Services, including text messages, chat, videos, photographs or
          profile text (hereinafter, “content” or “User Submission”; and (iii)
          your interactions with other Registered Users through the Services. As
          more fully described below, you may not post or transmit to the
          Company or other users (either on or off the Service), any offensive,
          inaccurate, incomplete, abusive, obscene, profane, threatening,
          intimidating, harassing, racially offensive, or illegal material, or
          any material that infringes or violates another person’s rights
          (including intellectual property rights, any rights or privacy and
          publicity).
        </li>
        <li>
          Without limiting the generality of the foregoing, by posting
          information or otherwise communicating by means of Guess the Rose, you
          agree that you will not upload, post, share, or otherwise distribute
          any User Submission that:
          <ol>
            <li>
              Is illegal, threatening, defamatory, abusive, harassing,
              degrading, intimidating, fraudulent, deceptive, invasive, racist,
              or contains any type of suggestive, inappropriate, or explicit
              language or confidential, non- public information about any third
              party;
            </li>
            <li>
              Is hate speech, threatening, or pornographic; promotes or incites
              violence; or contains nudity or graphic or gratuitous violence.
            </li>
            <li>
              Infringes on any trademark, patent, trade secret, copyright, or
              other proprietary right of any party;
            </li>
            <li>
              Contains any type of unauthorized or unsolicited advertising or
              commercial communications (such as spam);
            </li>
            <li>
              Impersonates any person or entity, including any Guess the Rose
              employees or representatives.
            </li>
            <li>
              We do our best to keep Guess the Rose safe, but we cannot
              guarantee it. We need your help to keep Guess the Rose safe, which
              includes the following commitments by you:
              <ol>
                <li>
                  You will not collect Users’ User Submissions or information,
                  or otherwise access Guess the Rose, using automated means
                  (such as harvesting bots, robots, spiders, or scrapers, or
                  automated pro-active following or un-following) without our
                  prior permission.
                </li>
                <li>
                  You will not engage in multi-level marketing, such as a
                  pyramid scheme, on Guess the Rose.
                </li>
                <li>You will not upload viruses or other malicious code.</li>
                <li>
                  You will not solicit login information or access an account
                  belonging to someone else.
                </li>
                <li>You will not bully, intimidate, or harass any User.</li>
                <li>
                  You will not use Guess the Rose to do anything unlawful,
                  misleading, malicious, or discriminatory.
                </li>
                <li>
                  You will not do anything that could disable, overburden, or
                  impair the proper working or appearance of Guess the Rose,
                  such as a denial of service attack or interference with page
                  rendering or other Guess the Rose functionality.
                </li>
                <li>
                  You will not facilitate or encourage any violations of this
                  Agreement or our policies.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Protecting Other People’s Rights. We respect other people’s rights,
          and expect you to do the same. You therefore agree that:
          <ol>
            <li>
              You will not post User Submission or take any action on Guess the
              Rose that infringes or violates someone else’s rights or otherwise
              violates the law.
            </li>
            <li>
              We can remove any User Submission or information you post if we
              believe that it violates this Statement or our policies.
            </li>
            <li>
              If you repeatedly infringe other people’s intellectual property
              rights, we will disable your account when appropriate.
            </li>
            <li>
              You will not use our copyrights or trademarks or any confusingly
              similar marks, except as expressly permitted or with our prior
              written permission.
            </li>
            <li>
              If you collect information from Users, you will: obtain their
              consent, make it clear you (and not Guess the Rose) are the one
              collecting their information, and post a privacy policy explaining
              what information you collect and how you will use it.
            </li>
            <li>
              You will not post anyone’s identification documents or sensitive
              financial information on Guess the Rose.
            </li>
            <li>
              You will not tag Users or send email invitations to non-Users
              without their consent.
            </li>
          </ol>
        </li>
        <li>
          Sharing Your User Submissions and Information.
          <ol>
            <li>
              We do not assume any liability for any User Submissions posted by
              you or any other 3rd party Users of our Services. However, subject
              to your privacy rights and application settings, you grant us a
              perpetual, irrevocable, non-exclusive, transferable,
              sub-licensable, royalty-free, worldwide license to use any User
              Submissions that you post on or in connection with Guess the Rose
              (“IP License”). This only refers and applies to User Submissions
              posted via open communication tools, and does not refer to
              information that is provided as part of the registration process,
              necessary in order to use our Services. All information provided
              as part of our registration process is covered by our Privacy
              Policy.
            </li>
            <li>
              We have the right at our sole discretion to remove any User
              Submission that, we feel in our judgment does not comply with this
              Agreement, along with any User Submission that we feel is
              otherwise offensive, harmful, objectionable, inaccurate, or
              violates any 3rd party copyrights or trademarks. We are not
              responsible for any delay or failure in removing such User
              Submission. If you post User Submission that we choose to remove,
              you hereby consent to such removal, and consent to waive any claim
              against us.
            </li>
            <li>
              When you delete an IP User Submission, it is deleted in a manner
              similar to emptying the recycle bin on a computer. However, you
              understand that removed User Submission may persist in backup
              copies for a reasonable period of time (but will not be available
              to others).
            </li>
            <li>
              When you post or publish any User Submission or information, it
              means that you are allowing everyone, including people off of
              Guess the Rose, to access and use that information, and to
              associate it with you (i.e., your name and profile picture).
            </li>
            <li>
              We always appreciate your feedback or other suggestions about
              Guess the Rose, but you understand that we may use your feedback
              or suggestions without any obligation to compensate you for them
              (just as you have no obligation to offer them).
            </li>
            <li>
              You represent and warrant:
              <ol>
                <li>
                  You are the owner of (or have all the rights and permission
                  required to use) all the intellectual property rights in the
                  User Submissions you post and have the written consent of each
                  and every identifiable natural person in the User Submissions
                  to use such person’s name or likeness in the manner
                  contemplated by the Service and this Agreement. Each person
                  has released you from any liability that may arise in relation
                  to such use.
                </li>
                <li>
                  The User Submissions, use of the User Submissions with the
                  Services and Guess the Rose’s use of such User Submissions
                  will not infringe any intellectual property rights,
                  proprietary rights, privacy rights, confidentiality and rights
                  of publicity or otherwise violate this Agreement or the rights
                  of any third party.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Risk Assumption and Precautions. You assume all risk when using the
          Services. The company makes no representations or warranties as to the
          conduct of users of their compatibility with any current or future
          users. The company is not responsible for the conduct of any user. At
          no event, shall the company be liable, directly or indirectly, for any
          losses or damages whatsoever, general, special, compensatory,
          consequential, and/or incidental, arising out of or relating to the
          conduct of you or anyone else in connection with the use of the
          service, including without limitation, death, bodily injury, emotional
          distress, and/or any other damages resulting in from communications or
          meetings with other users through the service. You should not provide
          your financial information or wire or otherwise send money, to other
          users.
        </li>
        <li>
          No Guarantees. You understand that Guess the Rose makes no guarantees,
          either explicit or implied, regarding the availability, accuracy,
          timeliness, or reliability of the services.
        </li>
        <li>
          Reporting of Violations. You will promptly report to Guess the Rose
          any violation of the Agreement by others, including but not limited
          to, Registered Users.
        </li>
        <li>
          Content Removal. Guess the Rose reserves the right, but has no
          obligation, to monitor the information or material you submit to the
          Services or post in the public areas of the Services. Guess the Rose
          will have the right to remove any such information or material that in
          its sole opinion violates, or may violate, any applicable law or
          either the letter or spirit of this Agreement or upon the request of
          any third party.
        </li>
        <li>
          No False Information. You will not provide inaccurate, misleading or
          false information to Guess the Rose or to any other user. If
          information provided to Guess the Rose or another user subsequently
          becomes inaccurate, misleading or false, you will promptly notify
          Guess the Rose of such change.
        </li>
        <li>
          No Advertising or Commercial Solicitation. You will not advertise or
          solicit any user to buy or sell any products or services through the
          Site or Services. You may not transmit any chain letters, junk or spam
          e-mail to other users. Further, you will not use any information
          obtained from the Services in order to contact, advertise to, solicit,
          or sell to any user without their prior explicit consent. If you
          breach the terms of this subsection and send or post unsolicited bulk
          email, “spam” or other unsolicited communications of any kind through
          the Services, you acknowledge that you will have caused substantial
          harm to Guess the Rose, but that the amount of such harm would be
          extremely difficult to ascertain. As a reasonable estimation of such
          harm, you agree to pay Guess the Rose $50 for each such unsolicited
          communication you send through the Services.
        </li>
        <li>
          No Harassment of Guess the Rose Employees or Agents. You will not
          harass, annoy, intimidate or threaten any Guess the Rose employees or
          agents engaged in providing any portion of the Services to you.
        </li>
      </ol>
      <h2>Proprietary Rights</h2>
      <ol>
        <li>
          Ownership of Proprietary Information. You hereby acknowledge and agree
          that Guess the Rose is the owner of highly valuable proprietary
          information, including without limitation, the scoring algorithm,
          profiles, and profile questions. Guess the Rose owns and hereby
          retains all proprietary rights in the Services and the Site, including
          but not limited to, all Confidential Information.
        </li>
        <li>
          No Use of Confidential Information. You will not post, copy, modify,
          transmit, disclose, show in public, create any derivative works from,
          distribute, make commercial use of, or reproduce in any way any (i)
          Confidential Information or (ii) other copyrighted material,
          trademarks, or other proprietary information accessible via the
          Services, without first obtaining the prior written consent of the
          owner of such proprietary rights.
        </li>
        <li>
          Other Users’ Information. Other Registered Users may post copyrighted
          information, which has copyright protection whether or not it is
          identified as copyrighted. You agree that you will not copy, modify,
          publish, transmit, distribute, perform, display, commercially use, or
          sell any Guess the Rose or third party proprietary information
          available via the Services or the Site.
        </li>
      </ol>
      <h2>Class Action Waiver</h2>
      <p>
        Any arbitration under these Terms and Conditions will take place on an
        individual basis; class arbitrations and class/representative/collective
        actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING
        CLAIMS AGAINST THE OTHER ONLY IN EACH’S INDIVIDUAL CAPACITY, AND NOT AS
        A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
        REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY
        GENERAL ACTION AGAINST THE OTHER. Further, unless both you and Employer
        agree otherwise, the arbitrator may not consolidate more than one
        person’s claims, and may not otherwise preside over any form of a
        representative or class proceeding.
      </p>
      <h2>User Information</h2>
      <p>
        Privacy Statement. For information about the collection and possible use
        of information and material provided by you, please read our Privacy
        Statement above. By using the Site or the Services, you are consenting
        to the terms of Guess the Rose’s Privacy Statement.
      </p>
      <p>
        No Contact Information. You agree that you will not post any e-mail
        addresses, personal website address or profile page you may have on a
        third party website, or other contact information in areas other than
        those specifically designated for this information, or in any other
        communications you may have with other users.
      </p>
      <p>
        Disclosure By Law. You acknowledge and agree that Guess the Rose may
        disclose information you provide if required to do so by law, at the
        request of a third party, or if we, in our sole discretion, believe that
        disclosure is reasonable to (1) comply with the law, requests or orders
        from law enforcement, or any legal process (whether or not such
        disclosure is required by applicable law); (2) protect or defend Guess
        the Rose, or a third party’s, rights or property; or (3) protect
        someone’s health or safety, such as when harm or violence against any
        person (including the user) is threatened.
      </p>
      <p>
        Use of Anonymous Information for Research. By using the Services, you
        agree to allow Guess the Rose to anonymously use the information from
        you and your experiences through the Services to continue Guess the
        Rose’s research into developing fun games and to improve the Services.
        This research, conducted by psychologists and behavior research
        scientists, may be published in academic journals. However, all of your
        responses will be anonymous, and we will not publish research containing
        your personal identifying information.
      </p>
      <p>
        Links to Third-Party Web Sites / Dealings with Advertisers and Sponsors.
        The Services may contain links to web sites of third-parties, including
        without limitation, advertisers, which are not under the control of
        Guess the Rose, and Guess the Rose is not responsible for the content of
        any linked site or any link contained in a linked site, or any changes
        or updates to such web sites. Guess the Rose provides these links to you
        only as a convenience, and the inclusion of any link does not imply that
        Guess the Rose endorses or accepts any responsibility for the content on
        such third-party web site. Your correspondence or business dealings
        with, or participation in promotions of, advertisers or sweepstakes
        sponsors found on or through the Services (including payment and
        delivery of related goods or services, any personal information or
        opt-in contact information voluntarily given to advertisers and
        sweepstake sponsors, and any other terms, conditions, warranties or
        representations associated with such dealings) are solely between you
        and such advertiser or sweepstakes sponsor. You agree that Guess the
        Rose will not be responsible or liable for any loss or damage of any
        sort incurred as the result of any such dealings, including the sharing
        of the information you supply to Guess the Rose with advertisers or
        sweepstakes sponsors, or as the result of the presence of such
        advertisers on the Services. Please visit our Privacy Statement to learn
        more about how we use your information.
      </p>
      <h2>Disclaimer of Warranty</h2>
      <ol>
        <li>
          No Warranties. THIS SECTION WILL APPLY TO THE MAXIMUM EXTENT PERMITTED
          BY APPLICABLE LAW. Guess the Rose PROVIDES THE SERVICES ON AN “AS IS”
          AND “AS AVAILABLE” BASIS AND GRANTS NO WARRANTIES OF ANY KIND, WHETHER
          EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO THE SERVICES
          OR THE SITE (INCLUDING ALL INFORMATION CONTAINED THEREIN), INCLUDING
          ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE OR NON-INFRINGEMENT. Guess the Rose DOES NOT WARRANT THAT YOUR
          USE OF THE SERVICES WILL BE SECURE, UNINTERRUPTED, ALWAYS AVAILABLE,
          ERROR-FREE OR WILL MEET YOUR REQUIREMENTS, OR THAT ANY DEFECTS IN THE
          SERVICES WILL BE CORRECTED. Guess the Rose DISCLAIMS LIABILITY FOR,
          AND NO WARRANTY IS MADE WITH RESPECT TO, THE CONNECTIVITY AND
          AVAILABILITY OF THE SERVICES.
        </li>
        <li>
          Third Party Content. Opinions, advice, statements, offers, or other
          information or content made available through the Services, but not
          directly by Guess the Rose, are those of their respective authors, and
          should not necessarily be relied upon. Such authors are solely
          responsible for such content. Guess the Rose DOES NOT: (i) GUARANTEE
          THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION PROVIDED
          ON THE SERVICES, OR (ii) ADOPT, ENDORSE OR ACCEPT RESPONSIBILITY FOR
          THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT MADE
          BY ANY PARTY OTHER THAN Guess the Rose. UNDER NO CIRCUMSTANCES WILL
          GUESS THE ROSE BE RESPONSIBLE FOR ANY LOSS OR DAMAGE RESULTING FROM
          ANYONE’S RELIANCE ON INFORMATION OR OTHER CONTENT POSTED ON THE
          SERVICES, OR TRANSMITTED TO OR BY ANY USERS.
        </li>
        <li>
          Beta Features. From time to time, Guess the Rose may offer new “beta”
          features or tools with which its users may experiment on the Services.
          Such features or tools are offered solely for experimental purposes
          and without any warranty of any kind, and may be modified or
          discontinued at Guess the Rose’s sole discretion. The provisions of
          this Disclaimer of Warranty section apply with full force to such
          features or tools.
        </li>
      </ol>
      <h2>Limitation of Liability</h2>
      <ol>
        <li>
          Incidental Damages and Aggregate Liability. IN NO EVENT WILL GUESS THE
          ROSE BE LIABLE FOR ANY INCIDENTAL, SPECIAL, CONSEQUENTIAL OR INDIRECT
          DAMAGES ARISING OUT OF OR RELATING TO THE USE OR INABILITY TO USE THE
          SERVICES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OR
          CORRUPTION OF DATA OR PROGRAMS, SERVICE INTERRUPTIONS AND PROCUREMENT
          OF SUBSTITUTE SERVICES, EVEN IF Guess the Rose KNOWS OR HAS BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </li>
        <li>
          No Liability for non-Guess the Rose Actions. TO THE MAXIMUM EXTENT
          PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL GUESS THE ROSE BE LIABLE
          FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL,
          SPECIAL, COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING OUT
          OF OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN CONNECTION WITH
          THE USE OF THE SERVICES, INCLUDING WITHOUT LIMITATION, BODILY INJURY,
          EMOTIONAL DISTRESS, AND/OR ANY OTHER DAMAGES RESULTING FROM
          COMMUNICATIONS OR MEETINGS WITH OTHER REGISTERED USERS OF THE
          SERVICES. THIS INCLUDES ANY CLAIMS, LOSSES OR DAMAGES ARISING FROM THE
          CONDUCT OF USERS WHO HAVE REGISTERED UNDER FALSE PRETENSES OR WHO
          ATTEMPT TO DEFRAUD OR HARM YOU.
        </li>
        <li>
          Information Verification. Guess the Rose and its contractors may use
          various ways of verifying information that users have provided.
          However, none of those ways are perfect, and you agree that Guess the
          Rose and its contractors will have no liability to you arising from
          any incorrectly verified information.
        </li>
      </ol>
      <h2>Indemnification</h2>
      <p>
        You agree to indemnify, defend and hold harmless Guess the Rose, its
        officers, directors, employees, agents and third parties, for any
        losses, costs, liabilities and expenses (including reasonable attorneys’
        fees) relating to or arising out of (a) your use of or inability to use
        the Site or Services, (b) any user postings made by you, (c) your
        violation of any terms of this Agreement or your violation of any rights
        of a third party, or (d) your violation of any applicable laws, rules or
        regulations. Guess the Rose reserves the right, at its own cost, to
        assume the exclusive defense and control of any matter otherwise subject
        to indemnification by you, in which event you will fully cooperate with
        Guess the Rose in asserting any available defenses.
      </p>
      <h2>Complaints / Law Enforcement Contact</h2>
      <p>
        To resolve a complaint regarding the Service, you e-mail us at
        guesstherosehq@gmail.com
      </p>
      <p>
        Notice For California Users : Under California Civil Code Section
        1789.3, California website users are entitled to know that they may file
        grievances and complaints with: the Complaint Assistance Unit of the
        Division of Consumer Services of the California Department of Consumer
        Affairs , in writing at 400 R Street, Suite 1080, Sacramento,
        California, 95814, or by telephone at (916) 445-1254 or (800) 952-5210,
        or by email at dca@dca.ca.gov.
      </p>
      <h2>Communication and Privacy</h2>
      <p>
        We may use your email address to send you messages notifying you of
        important changes to the Services or special offers.
      </p>
      <h2>Changes</h2>
      <p>
        Guess the Rose reserves the right, in its sole discretion, to change the
        Terms under which https://guesstherose.com/ is offered. The most current
        version of the Terms will supersede all previous versions. Guess the
        Rose encourages you to periodically review the Terms to stay informed of
        our updates.
      </p>
      <h2>Term and Termination</h2>
      <p>
        This Agreement will become effective upon your acceptance of the
        Agreement by your use of the Site or the Services and will remain in
        effect in perpetuity unless terminated hereunder. Either you or Guess
        the Rose may terminate your account at any time, for any reason or no
        reason, without explanation, effective upon sending written notice to
        the other party. Guess the Rose reserves the right to immediately
        suspend or terminate your access to any of the Services, without notice,
        for any reason or no reason. We also reserve the right to remove your
        account information or data from our Services and any other records at
        any time at our sole discretion. In the event your access to any of the
        Services is suspended due to the breach of this Agreement, you agree
        that all fees then paid to Guess the Rose by you will be nonrefundable
        and all outstanding or pending payments will immediately be due. You may
        terminate your account by visiting Settings page within the app.
      </p>
      <h2>Cancellations</h2>
      <p>
        Cancellation At Any Time. Except as otherwise stated in this section,
        you may cancel your registration or subscription to any Services at any
        time during the term of such registration or subscription by emailing
        guesstherosehq@gmail.com.
      </p>
      <h2>General Provisions</h2>
      <ol>
        <li>
          Controlling Law and Jurisdiction. You agree that Delaware law (without
          giving effect to its conflicts of law principles) will govern this
          Agreement, the Site and the Services and that any dispute arising out
          of or relating to this Agreement, the Site or the Services will be
          subject to the exclusive jurisdiction and venue of the federal and
          state courts in Delaware. You acknowledge and agree that any violation
          of this Agreement may cause Guess the Rose irreparable harm, and
          therefore agree that Guess the Rose will be entitled to seek
          extraordinary relief in court, including but not limited to temporary
          restraining orders, preliminary injunctions and permanent injunctions
          without the necessity of posting a bond or other security, in addition
          to and without prejudice to any other rights or remedies that Guess
          the Rose may have for a breach of this Agreement.
        </li>
        <li>
          Miscellaneous. This Agreement, which you accept upon registration for
          the Services, the Privacy Statement located on the Site, and any
          applicable payment, renewal, Additional Services terms, comprise the
          entire agreement between you and Guess the Rose regarding the use of
          this Service, superseding any prior agreements between you and Guess
          the Rose related to your use of this Site or Services (including, but
          not limited to, any prior versions of this Agreement). The FAQ’s found
          on the Services are for informational purposes only and are not deemed
          to be part of this Agreement. Unless otherwise explicitly stated, the
          Agreement will survive termination of your registration to the
          Service. The failure of Guess the Rose to exercise or enforce any
          right or provision of this Agreement does not constitute a waiver of
          such right or provision. If any provision of this Agreement is held
          invalid, the remainder of this Agreement will continue in full force
          and effect. Regardless of any statute or law to the contrary, any
          claim or cause of action arising out of or related to the use of the
          Site or this Agreement must be filed within one (1) year after such
          claim or cause of action arose or be forever barred. The section
          titles in this Agreement are for convenience only and have no legal or
          contractual effect.
        </li>
      </ol>
      <h3>Special Provisions Applicable to Users Outside the United States</h3>
      <p>
        We strive to create a global community with consistent standards for
        everyone, but we also strive to respect local laws. The following
        provisions apply to Users and non-Users who interact with Guess the Rose
        outside the United States:
      </p>
      <p>
        (a) You consent to having your personal data transferred to and
        processed in the United States.
      </p>
      <p>
        (b) If you are located in a country embargoed by the United States, or
        are on the U.S. Treasury Department’s list of Specially Designated
        Nationals you will not engage in commercial activities on Guess the Rose
        (such as payments). You will not use Guess the Rose if you are
        prohibited from receiving products, services, or software originating
        from the United States.
      </p>
      <h2>Digital Millennium Copyright Act Notice</h2>
      <p>
        Guess the Rose respects the intellectual property rights of others and
        expects its users to do the same. It is Guess the Rose’s policy, in
        appropriate circumstances and at its discretion, to disable and/or
        terminate the accounts of users who repeatedly infringe or are
        repeatedly charged with infringing the copyrights or other intellectual
        property rights of others. Guess the Rose will respond expeditiously to
        claims of copyright infringement committed using the Guess the Rose’s
        Site or other online network accessible through a mobile device or other
        type of device that are reported to Guess the Rose’s Copyright Agent.
      </p>
      <p>
        If you believe that your copyrighted work has been copied in a way that
        constitutes copyright infringement and is accessible on this Site,
        please notify Guess the Rose at guesstherosehq@gmail.com as set forth in
        the Digital Millennium Copyright Act of 1998 (DMCA). For your complaint
        to be valid under the DMCA, you must provide the following information
        in writing:
      </p>
      <ol>
        <li>
          Identify the copyrighted work that you claim has been infringed, or —
          if multiple copyrighted works are covered by the notice — you may
          provide a representative list of the copyrighted works that you claim
          have been infringed.
        </li>
        <li>
          Identify the material that you claim is infringing (or to be the
          subject of infringing activity) and that is to be removed or access to
          which is to be disabled, and information reasonably sufficient to
          permit us to locate the material, including at a minimum, if
          applicable, the URL of the link shown on the site where such material
          may be found.
        </li>
        <li>
          Provide your mailing address, telephone number, and, if available,
          email address.
        </li>
        <li>
          {' '}
          Include both of the following statements in the body of the notice:{' '}
          <ol>
            <li>
              “I hereby state that I have a good faith belief that the disputed
              use of the copyrighted material is not authorized by the copyright
              owner, its agent, or the law (e.g., as a fair use).”
            </li>
            <li>
              “I hereby state that the information in this Notice is accurate
              and, under penalty of perjury, that I am the owner, or authorized
              to act on behalf of the owner, of the copyright or of an exclusive
              right under the copyright that is allegedly infringed.”
            </li>
          </ol>
        </li>
        <li>
          Provide your full legal name and your electronic or physical
          signature.
        </li>
      </ol>
      <p>
        Deliver this notice, with all items completed, to Guess the Rose’s
        Copyright Agent:
      </p>
      <p>Copyright Agent</p>
      <p>Caldera, LLC</p>
      <p>Email: guesstherosehq@gmail.com</p>
      <p>
        This policy is effective as of <b>26 July 2020</b>.
      </p>
    </Main>
  )
}
